import React from 'react';
import Logo from '../images/logo-text.png';

function Footer() {
  return (<footer>
    <div className="max-w-6xl mx-auto px-4 sm:px-6">
      {/* Bottom area */}
      <div className="md:flex md:items-center md:justify-between py-4 md:py-8 border-t border-gray-200">
        {/* Copyrights note */}
        <div className="text-sm text-gray-600 mr-4">
          <a className="text-blue-600 hover:underline" href="https://www.divinetech.kr/">DivineTech.</a>.
          All rights reserved.
        </div>

        <img src={Logo} alt={"medical twin"} className={"bottom-logo"}/>

      </div>

    </div>
  </footer>);
}

export default Footer;
